@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dongle&family=Oxygen&family=Roboto&family=Space+Mono&family=Teko:wght@500&family=Ubuntu&family=Varela+Round&display=swap");
* {
  margin: 0;
  font-family: "Varela Round", sans-serif;
  color: #ffffff;
}

.App {
  background-color: #37425b;
  min-height: 100vh;
  overflow: auto;
  background-image: url(./backgroundimage.jpg);
  background-size: 50%;
}

/* Navbar */

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 90vh;
  margin: 0 auto;
  border-bottom: 3px solid #ffffff;
  margin-left: auto;
}

.navbar h1 {
  font-style: italic;
  font-weight: bold;
  text-shadow: 0px 0px 20px rgba(130, 130, 130, 0.9);
}

.links {
  margin-left: auto;
}

.navbar a {
  text-decoration: none;
  padding: 6px;
}

/* Home Page */

.home p {
  text-align: center;
}

.content {
  max-width: 700px;
  margin: 40px auto;
  padding: 20px;
  padding-top: 0;
}

/* Post List Page */

.post-list h1 {
  text-align: center;
  text-shadow: 0px 0px 20px rgb(130, 130, 130, 0.9);
}

.post {
  background-color: #000000;
  border: 3px solid #ffffff;
  border-radius: 13px;
  margin: 20px 0;
  text-align: center;
  overflow-wrap: break-word;
}

.post:hover {
  box-shadow: 0px 0px 20px rgba(130, 130, 130, 0.9);
}

.post h2 {
  font-size: 20px;
  color: #ffffff;
  padding-top: 3vh;
}

.post a {
  text-decoration: none;
}

.post p {
  padding: 1vh 1vh 3vh 1vh;
  font-size: 19px;
  
}

.post div {
  padding-bottom: 1.5vh;
  padding-left: 1vh;
  padding-right: 1vh;
}

/* User Post List */

.users-posts p {
  text-align: center;
}

.user-post-list h1 {
  overflow-wrap: break-word;
  text-align: center;
  text-shadow: 0px 0px 20px rgb(130, 130, 130, 0.9);
}

.user-post {
  background-color: #000000;
  border: 3px solid #ffffff;
  border-radius: 13px;
  margin: 20px 0;
  text-align: center;
}

.user-post h2 {
  font-size: 20px;
  color: #ffffff;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

.user-post p {
  padding: 1vh 1vh 3vh 1vh;
  font-size: 19px;
  overflow-wrap: break-word;
}

/* Create new post page */

.create {
  background-color: #000000;
  border: 3px solid #ffffff;
  border-radius: 13px;
  padding: 3vh;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

.create label {
  text-align: left;
  display: flex;
}

.create h2 {
  font-size: 20px;
  padding-bottom: 30px;
}

.create input,
.create textarea,
.create select {
  min-width: 100%;
  max-width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  color: #000000;
}

.create button {
  background: #ffffff;
  border: 0;
  padding: 8px;
  border-radius: 13px;
  cursor: pointer;
  color: #000000;
}
